import * as Sentry from '@sentry/browser'
import type {
	ApplicantWithRelationships,
	ExamSessionAnswer,
	ExamSessionWithAnswers,
	ExamWithQuestions,
} from './types'
import { api, exam as examApi } from './api'
import axios from 'axios'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'

function reportErrorToSentry(error: any) {
	const errorInfo = {
		url: error.config?.url,
		method: error.config?.method,
		data: error.config?.data,
		status: error.response?.status,
		response: error.response?.data,
	}

	Sentry.withScope((scope) => {
		scope.setExtras(errorInfo)
		Sentry.captureException(error)
	})
}

axios.defaults.baseURL = import.meta.env.VITE_BAGEL_BASE_URL as string
axios.defaults.timeout = 10000 // 10 seconds timeout

// Axios Interceptor for handling errors
axios.interceptors.response.use(
	response => response,
	async (error) => {
		reportErrorToSentry(error)
		return Promise.reject(error)
	}
)

let addToNetworkStackTO: ReturnType<typeof setTimeout> | null = null

export interface AnswerType {
	answer_id?: string
	question_id: string
	value?: any
}

export interface ExamStore {
	isOffline: boolean
	currentQuestion: number
	isSubmitting: boolean
	isFinished: boolean
	exam: null | ExamWithQuestions
	exam_session: null | undefined | ExamSessionWithAnswers
	exam_session_user_answers: undefined | ExamSessionAnswer[]
	applicant_id: string
	applicant: undefined | ApplicantWithRelationships
	isLoading: boolean
}

export const useStore = defineStore('exam', {
	state: (): ExamStore => ({
		isOffline: false,
		currentQuestion: 0,
		isSubmitting: false,
		isFinished: false,
		exam: null,
		exam_session: null,
		exam_session_user_answers: undefined,
		applicant_id: '',
		applicant: undefined,
		isLoading: false,
	}),

	getters: {
		getCurrentQuestion: state => state.currentQuestion,
		getIsSubmitting: state => state.isSubmitting,
		getIsFinished: state => state.isFinished,
		getChapters: state => state.exam_session?.exam?.chapters.sort(
			(a, b) => Number(a.order) - Number(b.order)
		) || [],
	},

	actions: {
		async updatePerson(data: Record<string, any>) {
			if (!this.applicant) return

			const { person_id } = this.applicant
			if (!person_id) return
			await axios.put(`/person/${person_id}`, data)
		},

		async finishExam() {
			try {
				// ! make sure that we push all the answers to the server successfully before finishing the exam
				clearTimeout(addToNetworkStackTO!)
				await this.flushNetworkStack()
				localStorage.removeItem(this.exam_session?.id || '')
				// ! make sure that we push all the answers to the server successfully before finishing the exam

				const session_id = this.exam_session?.id
				await axios.post(`/exam/session/${session_id}/finish`)

				await this.router.push('/exam-finish')
			} catch (e) {
				console.error(e)
			}
		},

		setExamSessionUserAnswers(session_id: string, answers?: ExamSessionAnswer[]) {
			this.exam_session_user_answers = useLocalStorage<undefined | undefined | ExamSessionAnswer[]>(
				session_id,
				answers
			).value
		},

		async loadApplicant(applicant_id: string) {
			const applicant = (await api.applicant.get(applicant_id)).data
			this.applicant = applicant
			this.applicant_id = applicant_id

			this.exam_session = applicant.exam_sessions.find(
				session => session.is_finished === false
			)

			this.setExamSessionUserAnswers(this.exam_session?.id || '', this.exam_session?.user_answers)

			const finished_session = applicant.exam_sessions.find(
				e => e.is_finished
			)

			if (this.exam_session?.exam) {
				this.exam_session.exam.chapters = this.exam_session.exam.chapters.sort(
					(a, b) => Number(a.order) - Number(b.order)
				) || []
			} else if (finished_session && !applicant.allow_another_exam) {
				this.isFinished = true
				this.router.push('/exam-finish')
			}
		},

		async initExam(exam_id: string) {
			try {
				const { data: exam } = await axios.get(`/exam/${exam_id}`)
				this.exam = exam
			} catch (e) {
				console.error(e)
				setTimeout(() => {
					window.location.reload()
				}, 5000)
			}
		},

		continueSession() {
			if (!this.exam_session || !this.exam) return
			const firstChapter = this.getChapters[0]

			this.router.push(`/exam/${this.applicant_id}/chapter/${firstChapter.id}`)
		},

		async startExam() {
			if (this.isLoading) return
			this.isLoading = true
			if (!this.exam_session) {
				const payload = { applicant_id: this.applicant_id }
				const { data: exam_session } = await axios.post(
					`/exam/${this.exam?.id}/start`,
					payload
				)
				this.exam_session = exam_session
			}

			this.setExamSessionUserAnswers(this.exam_session?.id || '', this.exam_session?.user_answers)

			await this.router.push(
				`/exam/${this.applicant_id}/chapter/${this.getChapters[0].id}`
			)
			this.isLoading = false
		},

		nextQuestion() {
			this.currentQuestion++
		},

		previousQuestion() {
			this.currentQuestion--
		},

		async submitAnswer({ answer_id, question_id, value }: AnswerType) {
			if (!this.exam_session || !this.exam_session_user_answers) return

			if (Array.isArray(value)) value = value.join(',')

			const answer: ExamSessionAnswer = { answer_id, question_id, value, exam_session_id: this.exam_session.id || '' }

			const index = this.exam_session_user_answers.findIndex(
				a => a.question_id === question_id
			) ?? -1

			if (index !== -1) this.exam_session_user_answers[index] = answer
			else this.exam_session_user_answers.push(answer)

			if (addToNetworkStackTO) clearTimeout(addToNetworkStackTO)
			addToNetworkStackTO = setTimeout(() => { this.flushNetworkStack() }, 20_000)
		},
		async flushNetworkStack() {
			await examApi.session.answer(this.exam_session_user_answers || [], this.exam_session?.id)
		},

		resetExam() {
			this.currentQuestion = 0
			this.isSubmitting = false
			this.isFinished = false
		},
	},
})

if (import.meta.env.DEV && import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot))
}
