import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'home',
			component: Home,
		},
		{
			path: '/portal/:applicant_id',
			name: 'NitzanimToTechPortal',
			component: async () => import('@/views/Portal.vue'),
		},
		{
			path: '/register/:batchSlug?',
			name: 'Register',
			component: async () => import('@/views/Register.vue'),
			beforeEnter: (to) => {
				if (!to.params.batchSlug.length) {
					return '/register/nitzanim-2024'
				}
			}
		},
		{
			path: '/register-to-tech/:batchSlug?',
			name: 'RegisterToTech',
			component: async () => import('@/views/RegisterToTech.vue'),
			beforeEnter: (to) => {
				if (!to.params.batchSlug.length) {
					return '/register-to-tech/tech-devops-2024'
				}
			}
		},
		{
			path: '/exam/',
			name: 'Exam',
			component: async () => import('@/views/Exam/Index.vue'),
			children: [
				{
					path: '/exam/:applicant_id',
					name: 'ExamIndex',
					component: async () => import('@/views/Exam/ExamApplicant.vue'),
				},
				{
					path: '/exam/:applicant_id/chapter/:chapter_id',
					name: 'ExamChapter',
					component: async () => import('@/views/Exam/Chapter.vue'),
				},
			],
		},
		{
			path: '/contract/:applicant_id',
			name: 'Contract',
			component: async () => import('@/views/studentPayment/Contract.vue'),
		},
		{
			path: '/to-tech-contract/:applicant_id',
			name: 'ToTechContract',
			component: async () => import('@/views/studentPayment/ToTechContract.vue'),
		},
		{
			path: '/equipment/:applicant_id',
			name: 'Equipment',
			component: async () => import('@/views/studentPayment/Equipment.vue'),
		},
		{
			path: '/payment/:applicant_id',
			name: 'Payment',
			component: async () => import('@/views/studentPayment/Payment.vue'),
		},
		{
			path: '/success',
			name: 'Success',
			component: async () => import('@/views/studentPayment/Success.vue'),
		},
		{
			path: '/schedule/:applicant_id',
			name: 'Schedule',
			component: async () => import('@/views/Schedule.vue'),
		},
		{
			path: '/fail',
			name: 'Fail',
			component: async () => import('@/views/Fail.vue'),
		},
		{
			path: '/exam-finish',
			name: 'ExamFinish',
			component: async () => import('@/views/ExamSuccess.vue'),
		},
		{
			path: '/QuestionCode',
			name: 'QuestionCode',
			component: async () => import('@/components/QuestionCode.vue'),
		},
		{
			path: '/:catchAll(.*)*',
			name: 'Notfound',
			component: async () => import('@/views/Notfound.vue'),
		},
	],
	scrollBehavior(to) {
		if (to.hash) {
			return {
				behavior: 'smooth',
				el: to.hash,
				offset: { top: 0, left: 0 },
			}
		}
		return { top: 0, left: 0 }
	},
})

router.beforeEach((to, from, next) => {
	if (from.query.lang && !to.query.lang) {
		next({ ...to, query: { ...to.query, lang: from.query.lang } })
		return
	}

	next()
})

export default router
